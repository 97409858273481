<template>
  <div :class="`my-5 ${isMobile ? 'w-100' : 'w-50'}`">
    <div v-if="userInfo">
      <div class="box-white d-flex flex-column" v-if="userInfo">
        <div class="mt-2 vertical-center d-flex justify-content-between">
          <img class="circle-image-150" :src="userInfo.photoUrl ? userInfo.photoUrl : assets.profile" />
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-20 font-bold`">{{ userInfo.userName }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between">
          <span class="font-12 alpha-07">{{ $t('email') }}</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ getUserEmail(userInfo.email) }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between">
          <span class="font-12 alpha-07">Phone</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ userInfo.phone }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between">
          <span class="font-12 alpha-07">Gender</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ getGender(userInfo.gender) }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between">
          <span class="font-12 alpha-07">Birthday</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ userInfo.birthday }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between">
          <span class="font-12 alpha-07">Genre</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ userInfo.genre }}</span>
        </div>
        <div class="mt-8 d-flex justify-content-between">
          <span class="font-12 alpha-07">Created At</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-12`">{{ getDateStringFromTimestamp(userInfo.createdAt) }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between" v-if="hostname === 'localhost'">
          <span class="font-12 alpha-07">Follower Count</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-12`">{{ getDecimalFormat(userInfo.followerCount) }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between" v-if="hostname === 'localhost'">
          <span class="font-12 alpha-07">Following Count</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-12`">{{ getDecimalFormat(userInfo.followingCount) }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between" v-if="userInfo.updatedAt">
          <span class="font-12 alpha-07">Updated At</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-12`">{{ getDateStringFromTimestamp(userInfo.updatedAt) }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between" v-if="userInfo.blockedAt">
          <span class="font-12 alpha-07 color-red">Blocked At</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-12 color-red`">{{ getDateStringFromTimestamp(userInfo.blockedAt) }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between" v-if="userInfo.deletedAt">
          <span class="font-12 alpha-07 color-red">Deleted At</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-12 color-red`">{{ getDateStringFromTimestamp(userInfo.deletedAt) }}</span>
        </div>
        <span>&nbsp;</span>
      </div>
      <div class="my-5 box-white d-flex flex-column" v-if="(userInfo && userInfo.organizerInfo)">
        <span class="mt-5 font-15 font-bold color-red" v-if="(userInfo.userType === -1)">Unapproved Organizer</span>
        <div class="mt-5 d-flex justify-content-between">
          <span class="font-12 alpha-07">Organizer Name</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ userInfo.organizerInfo.name }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between">
          <span class="font-12 alpha-07">{{ $t('tax_code') }}</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ userInfo.organizerInfo.taxCode }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between">
          <span class="font-12 alpha-07">UST ID</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ userInfo.organizerInfo.ustId }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between">
          <span class="font-12 alpha-07">Address</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ userInfo.organizerInfo.address }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between">
          <span class="font-12 alpha-07">Organizer Phone</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ userInfo.organizerInfo.phone }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between">
          <span class="font-12 alpha-07">Account Holder Name</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ userInfo.organizerInfo.cardName }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between">
          <span class="font-12 alpha-07">IBAN-Nummer</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ userInfo.organizerInfo.cardNumber }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between" v-if="userInfo.organizerInfo.cardBIC">
          <span class="font-12 alpha-07">BIC</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ userInfo.organizerInfo.cardBIC }}</span>
        </div>
        <div class="mt-5 d-flex justify-content-between" v-if="userInfo.organizerInfo.swiftCode">
          <span class="font-12 alpha-07">Swift Code</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ userInfo.organizerInfo.swiftCode }}</span>
        </div>
        <div class="my-5 d-flex justify-content-between">
          <span class="font-12 alpha-07">Description</span>
          <span :class="`${isMobile ? '' : 'col-sm-8'} font-15 font-bold`">{{ userInfo.organizerInfo.description }}</span>
        </div>
      </div>
      <div class="mt-5 d-flex justify-content-end" v-if="(userInfo.userType === -1)">
        <v-btn class="button-normal" @click="setUserTypeTask(1)">
          <span>Approve</span>
        </v-btn>
        <v-btn class="ml-10 button-red" @click="setUserTypeTask(0)">
          <span>Decline</span>
        </v-btn>
      </div>
      <div class="mt-5 d-flex justify-content-end" v-else-if="!userInfo.deletedAt">
        <v-btn class="button-normal" @click="unblockUserInfoTask()" v-if="userInfo.blockedAt">
          <span>Unblock</span>
        </v-btn>
        <v-btn class="ml-10 button-red" @click="blockUserInfoTask()" v-else>
          <span>Block</span>
        </v-btn>
        <v-btn class="ml-10 button-red" @click="deleteUserInfoTask()">
          <span>Delete</span>
        </v-btn>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <p>Please wait ...</p>
      <b-spinner type="grow"></b-spinner>
    </div>
  </div>
</template>


<script>
import { httpsCallable } from 'firebase/functions';
import { functions, firestore } from '../../../main';
import { onSnapshot, doc } from 'firebase/firestore';
import { getDateStringFromTimestamp, getDecimalFormat, getUserEmail, showLoading } from '../../../functions';

import profile from '@/assets/image/profile.jpg';

export default {
  name: 'UserDetail',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      assets: {
        profile
      },
      hostname: window.location.hostname,
      userInfo: null,
      snapUserInfo: null
    }
  },
  mounted() {
    this.getUserInfoTask();
  },
  destroyed() {
    if (this.snapUserInfo) {
      this.snapUserInfo();
    }
  },
  methods: {
    getDateStringFromTimestamp,
    getDecimalFormat,
    getUserEmail,
    getGender(gender) {
      switch (gender) {
        case 1: return 'Male';
        case 2: return 'Female';
        default: return 'Undefined';
      }
    },
    getUserInfoTask() {
      if (this.snapUserInfo) {
        return;
      }
      this.snapUserInfo = onSnapshot(doc(firestore, 'user', this.$route.params.userId), doc => {
        this.userInfo = doc.data();
      });
    },
    deleteUserInfoTask() {
      if (!confirm(this.$t('confirm_delete'))) {
        return;
      }
      const params = {
        functionName: 'deleteUser',
        userId: this.$route.params.userId,
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    blockUserInfoTask() {
      if (!confirm('Are you sure you want to block this user?')) {
        return;
      }
      const params = {
        functionName: 'blockUser',
        userId: this.$route.params.userId,
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    unblockUserInfoTask() {
      if (!confirm('Are you sure you want to unblock this user?')) {
        return;
      }
      const params = {
        functionName: 'unblockUser',
        userId: this.$route.params.userId,
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    setUserTypeTask(userType) {
      if (!confirm(`Are you sure you want to approve this user as ${userType === 1 ? 'Organizer' : 'Single User'}?`)) {
        return;
      }
      const params = {
        functionName: 'setUserType',
        userId: this.$route.params.userId,
        userType: userType
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success('Approved successfully.');
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>